export const getAuthorUrl = function(authorsRootSlug, authorSlug) {
  return isAuthorRootSlugDefined(authorsRootSlug) ? `/${authorsRootSlug.current}/${authorSlug.current}/` : `/${authorSlug.current}/`
}

export const getCategoryUrl = (tagsPage, categorySlug, useCategoriesRoot) => {
  return useCategoriesRoot && tagsPage != null ? `/${getSlugFromRedirectValidatingDocument(tagsPage).current}/${categorySlug.current}/` : `/${categorySlug.current}/`
}

export const getSlugFromRedirectValidatingDocument = (node) => {
  return node.slugWithRedirectValidation.slug
}

export const getAuthorRootSlugValue = function(authorsRootSlug) {
  return isAuthorRootSlugDefined(authorsRootSlug) ? authorsRootSlug.current : ''
}

export const getCategoryRootSlugValue = (categoriesRootSlug, useCategoriesRoot) => {
  return useCategoriesRoot ? categoriesRootSlug.current : ''
}

const isAuthorRootSlugDefined = (authorsRootSlug) => {
  return authorsRootSlug != null && authorsRootSlug.current 
}